import './Team.css'
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import TeamCard from "../../../components/TeamCard/TeamCard"

const items = [
    <TeamCard
        testimony="Visionary & Expert in creating leading projects."
        image="https://i.imgur.com/CmxFK57.png"
        name={<span className="notranslate">Vedat Gedik</span>}
        work={<span className="notranslate">Strategist</span>}
    />,
    <TeamCard
        testimony="Ensures the right people are connected."
        image="https://i.imgur.com/mIEgivA.png"
        name={<span className="notranslate">Mathias Pogba</span>}
        work={<span className="notranslate">Catalyst</span>}
    />,
    <TeamCard
        testimony="Managing the company and all legal aspects."
        image="https://i.imgur.com/hjhBilE.jpeg"
        name={<span className="notranslate">Danleeh Steven Nyirenda</span>}
        work={<span className="notranslate">CEO</span>}
    />,
    <TeamCard
        testimony="Ensures all operational aspects of the company & employee management."
        image="https://i.imgur.com/hjhBilE.jpeg"
        name={<span className="notranslate">Mzee Ramadan Juma</span>}
        work={<span className="notranslate">COO</span>}
    />
];

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1000: { items: 2 }
};
const Team = () => {

    return (
        <div id='team'>
            <section className='section team center' >
                <h2 className='section__title'>The core team</h2>
            </section>
            <div className="testimonial-container">
                <AliceCarousel
                    mouseTracking
                    items={items}
                    responsive={responsive}
                    autoPlay
                    infinite
                    autoPlayInterval={2000}
                    animationDuration={2000}
                />
            </div>
        </div>

    )
}

export default Team