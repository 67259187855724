import "./Success.css";
import CountUp from "react-countup";
import { motion } from "framer-motion";
import Grid from "@mui/material/Grid";
import {Stack, useMediaQuery} from "@mui/material";
import React from "react";
const Success = () => {
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <section  className='section success center' id='sucess'>
                <div className='title'>
                    <p className='success__title'>
                        Numbers
                    </p>
                </div>
                <Grid container padding={6} spacing={2} columns={matches ? (2) : (1)} className='mobile_grid'>
                    <Grid item xs={1}>
                        <h1>
                            Quick <br />
                            Overview<br />
                        </h1>
                        <p className='success__desc'>Save Time with Proven Results.</p>
                        <br />
                        <Stack direction="row" spacing={1}>
                            <h4 className='success__name'>Access to</h4>
                            <h2><span className='success__nb'><CountUp start={0} end={10} duration={4}/></span>
                            </h2>
                            <h4 className='success__name'>gold mines in Tanzania</h4>
                        </Stack>
                        <br/>
                        <Stack direction="row" spacing={1}>
                            <h4 className='success__name'>Access to</h4>
                            <h2>
                                <span className='success__nb'> Coal, Copper </span>
                            </h2>
                            <h4 className='success__name'>&</h4>
                            <h2>
                                <span className='success__nb'> Nickel </span>
                            </h2>
                        </Stack>
                        <br/>
                        <Stack direction="row" spacing={1}>
                                <h4 className='success__name'>From </h4>
                                <h2>
                                    <span className='success__nb'><CountUp start={0} end={1} duration={4} />kg </span>
                                </h2>
                            <h4 className='success__name'>test to several hundred / month</h4>
                        </Stack>
                    </Grid>
                    <Grid item xs={1}>
                        <motion.div
                                initial={{ x: "7rem", opacity: 0 }}
                                animate={{ x: "0", opacity: 1 }}
                                transition={{
                                    duration: 2,
                                    type: "spring"
                                }}
                                className="image-container"
                            >
                                <img src={"https://i.imgur.com/Rkn6I0x.jpeg"} alt="success" />
                        </motion.div>
                    </Grid>
                </Grid>
        </section>
    );
};
export default Success
