import './Faq.css'

const Faq = () => {
    return (
        <>
            <section className='section faq center' id='faq'>
                <h2 className='section__title'>Still have questions?</h2>
                <p className='faq__desc'>Contact us today for more information and start investing in Tanzania mining network.</p>
                <hr/>
            </section>
        </>
    )
}

export default Faq