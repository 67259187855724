import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {projects, event} from '../../content'
import './Navbar.css'

const Navbar = () => {
    const [showNavList, setShowNavList] = useState(false)

    const toggleNavList = () => setShowNavList(!showNavList)

    return (
        <nav className='center nav'>
            <ul
                style={{ display: showNavList ? 'flex' : null }}
                className='nav__list'
            >
                {projects.length ? (
                    <li className='nav__list-item'>
                        <a
                            href='#projects'
                            onClick={toggleNavList}
                            className='link link--nav'
                        >
                            Mission
                        </a>
                    </li>
                ) : null}

                {event ? (
                    <li className='nav__list-item'>
                        <a
                            href='#event'
                            onClick={toggleNavList}
                            className='link link--nav'
                        >
                            Vision
                        </a>
                    </li>
                ) : null}
                    <li className='nav__list-item'>
                        <a
                            href='#contact'
                            onClick={toggleNavList}
                            className='link link--nav'
                        >
                            Team
                        </a>
                    </li>
            </ul>
            <a href={'#contact'}>
                <span type='button' className='btn btn--outline mobile_button_nav' >
                    Contact us
                </span>
            </a>
            <button
                type='button'
                onClick={toggleNavList}
                className='btn btn--icon nav__hamburger'
                aria-label='toggle navigation'
            >
                {showNavList ? <CloseIcon /> : <MenuIcon />}
            </button>
        </nav>
    )
}

export default Navbar
