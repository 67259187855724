import {inDev} from '../../../content'
import React from 'react'
import './InDev.css'
const InDev = () => {
    const {name, text1,text2 } = inDev

    return (
        <section className='inDev' id='event'>
            <div className='inDev center'>
                <h2 className='inDev__role'>{name}</h2>
                <img style={{width:"300px", height:"300px"}} src={"https://i.imgur.com/FaifS8Y.png"} alt="logo"/>
                <p className='inDev__desc'>{text1}</p>
                <p className='inDev__desc'>{text2}</p>
                <div className='inDev__contact' >
                    <a href={'https://form.123formbuilder.com/6729923/contact-form'}>
                        <span type='button' className='btn btn--outline'>
                           Contact Form
                        </span>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default InDev
