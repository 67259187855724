const header = {
    homepage: '#',
    title: 'Tanzania mining network',
}

const about = {
    name: 'Tanzania mining network',
    role: 'Allows mine owners in Tanzania to sell their production (Gold, Silver, Copper and precious metals) in complete security and transparency',
    description:
        'Collaborate with our international buyers',
    text:'What is happening in Tanzania is incredible. The country is structuring itself, the institutions are ensuring the proper development of all activities. It allows us to innovate in the mining industry with a startup approach, thus facilitating the negotiation, due diligence and logistics aspects.',
    resume: 'mailto: ',
    social: {
        linkedin: 'https://linkedin.com',
    },
}

const projects = [
    {
        name: 'Show miners production',
        description:
            'Discover the vast quantities and quality of precious metals available in the country and find the best opportunity as a buyer.',
        livePreview: '#',
    },
    {
        name: 'Understand buyer needs',
        description:
            'Our team of experts will understand your purchasing needs and help you close long-term deals to give you peace of mind.',
        livePreview: '#',
    },
    {
        name: 'Start building your future',
        description:
            'Look at everything we have available accompanied by prices according to the quantities you want to develop your activities.',
        livePreview: '#',
    },
]

const event = {
    name: 'Develop the country’s mining activities',
    description:
        'Providing due diligence and negotiation support to mine owners is of paramount importance. Furthermore, structuring their deals with medium and long term contracts allows them to plan for the future and to be supported by local banks in order to invest in new extraction and transport machines.',
    resume: '#',
}

const data = [
    {
        description : "Save Time with Proven Results."
    }
]

const inDev = {
    name: 'Tanzania Mining Network Company LTD',
    text1:
        'Region Geita, District Nyang’hwale, Ward Kakora, Postal code 30202, Street Kakora, Road Kakora, Plot number 237,  Block number 54, House number 87.',
    text2:
        'P.O.BOX 4006',
    resume: '#',
}


export { header, about, projects, event ,data, inDev}
