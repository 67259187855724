import './Contact.css'

const Contact = () => {
    return (
        <section className='section contact center' id='contact'>

        </section>
    )
}

export default Contact