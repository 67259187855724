import { header } from '../../../content'
import Navbar from '../../../components/Navbar/Navbar'
import './Header.css'
import React from "react";
const Header = () => {
    const { homepage, title } = header

    return (
        <header className='header center'>
            <h3>
                {homepage ? (
                    <a href={homepage} className='link'>
                        {title}
                    </a>
                ) : (
                    title
                )}

            </h3>
            <img className='header_logo' src={"https://i.imgur.com/BQ3ZxVd.png"}
                 alt="logo"/>
            <Navbar/>
        </header>
    )
}

export default Header
